import { BehaviorSubject } from 'rxjs';

import {
  AppConfig,
  ConfigOverwriteByEnv,
  getConfig,
  PartnerConfig,
  Rooms,
} from '@gaming1/g1-config';

const defaultCsp = getConfig().security.csp;

export const baseConfig: PartnerConfig = {
  betting: {
    boostus: {
      enabled: true,
    },
    dailyOffers: {
      enabled: true,
    },
    defaultOddsUnit: 'american',
    enabled: true,
    favorites: {
      enabled: true,
    },
    lmt: {
      config: {
        disablePitchStripes: true,
        layout: 'single',
        pitchCustomBgColor: '#293342',
        tabsPosition: 'bottom',
      },
      enabled: true,
      fakeId: 0,
      theme: 'betly',
      url: 'de4f75930e98f328c9e763240489caf5',
    },
    minimalAge: 21,
    promotions: { enabled: true },
    showEmailNotifications: true,
    superOdds: {
      enabled: true,
    },
    tournamentor: {
      enabled: true,
    },
  },
  core: {
    associatedDomains: ['betlytn-us-acc01.gaming1.com', 'tn.betly.com'],
    privacySettings: {
      type: 'none',
    },
    mobileAppIdentifiers: {
      android: 'com.gamewise.us.betly.tn.acceptance',
      ios: '1618972084',
    },
    privacyPoliciesContentUid: 'privacy-policy',
    skipRegistrationSteps: true,
    termsAndConditionsContentUid: 'terms-of-service',
    zendeskWidgetKey: '79148a50-b216-4eea-bfdf-2f5cfc340843',
  },
  gaming: {
    enabled: false,
  },
  i18n: {
    availableLanguages: ['en'],
    dateFormat: 'MM/dd/yyyy',
    dateShortFormat: 'MM/dd',
    dateTimeFormat: 'MM/dd/yyyy hh:mm:ss aa',
    dateTimePartialFormat: 'MM/dd/yyyy hh:mm aa',
    dateTimeShortPartialFormat: 'MM/dd hh:mm aa',
    defaultLanguage: 'en',
    timeFormat: 'hh:mm:ss aa',
    timePartialFormat: 'hh:mm aa',
  },
  loyalty: {
    loggedOutCmsHomePage: true,
  },
  marketing: {
    blog: {
      enabled: true,
    },
    promotions: {
      enabled: true,
    },
  },
  network: {
    apiUrl: 'https://apifront-betlytn-us-front06.gaming1.com',
    // US sessions are really short so the keepalive requests should be frequent
    keepAliveInterval: 1 * 30 * 1000,
    mediaUrl: 'https://media-betlytn-us-front06.gaming1.com',
    prismicUrl: 'https://betlytn.cdn.prismic.io/api/v2',
    siteUrl: 'https://betlytn-us-front06.gaming1.com',
    wsUrl: 'wss://wss-betlytn-us-front06.gaming1.com:10002',
    zendeskUrl: 'https://support.tn.betly.com',
  },
  room: {
    roomName: Rooms.BETLYTN,
  },
  user: {
    amountIconSide: 'left',
    bonus: false,
    clockFormat: "hh':'mm aa",
    closeAccount: true,
    defaultCountry: 'us',
    deleteMobileAppAccount: true,
    deleteMobileAppAccountDirtyFlow: true,
    displayPersonalDataAddressState: true,
    displayPersonalDataBirthCity: false,
    displayPersonalDataBirthCountry: false,
    displayPersonalDataGender: false,
    displayPersonalDataPhoneNumber: ['mobile'],
    formValidations: {
      arePasswordSpecialCharsOnlyAdvised: true,
    },
    fullAddressOrder: ['Line1', 'Line2', 'City', 'ZipCode'],
    fullAddressSeparator: ', ',
    gamePause: true,
    gamePauseDurations: [
      { duration: 3, unit: 'day' },
      { duration: 7, unit: 'day' },
      { duration: 14, unit: 'day' },
      { duration: 1, unit: 'month' },
    ],
    gamePauseInput: 'radio',
    globalSelfExclusion: true,
    globalSelfExclusionExternalRedirectionUrls: {
      en: 'https://www.tn.gov/behavioral-health/substance-abuse-services/prevention/tennessee-redline.html',
    },
    globalSelfExclusionDurations: [
      { duration: 6, unit: 'month' },
      { duration: 1, unit: 'year' },
      { duration: 3, unit: 'year' },
      { duration: 5, unit: 'year' },
    ],
    globalSelfExclusionInput: 'radio',
    histories: {
      deposit: true,
      shop: false,
    },
    inactivityLogoutModal: true,
    inbox: true,
    isFulltimeLiveChatForVipEnabled: true,
    isLicenseSelectionEnabled: false,
    isLiveChatEnabled: true,
    inboxProjectKey: '7e266f6a4b7c3c3da2632bd03a5df227',
    legalModal: false,
    limits: {
      connectionTime: true,
      coolOffInMinute: 1440,
      deposit: true,
      lossBetting: true,
      spentTimeBetting: true,
      wageBetting: true,
    },
    multiFactorAuthentication: 'pinCode',
    privacyConditionsModal: false,
    registrationSuccessCheckAutenticationStatusPolling: true,
    remainingTimeLimit: true,
    selfExclusion: false,
    showLastConnection: true,
    zendeskUserNameFieldId: 7027610286365,
  },
  security: {
    csp: {
      ...defaultCsp,
      'connect-src': [
        ...defaultCsp['connect-src'],
        'https://*.betly.com',
        'wss://*.betly.com',
      ],
      'frame-src': [
        ...defaultCsp['frame-src'],
        'https://cashier.tn.betly.com',
        'https://betlytn.prismic.io',
      ],
    },
  },
  seo: {
    dataDog: {
      applicationId: '9f55ed26-8e83-4d39-8a49-a4a6cbf5052f',
      clientToken: 'pub53c7f763cb840a5ff92d91273489c975',
      partnerTag: 'tennessee.betly.com',
      site: 'datadoghq.eu',
    },
  },
};

export const configOverwrite: ConfigOverwriteByEnv = {
  local: {
    core: {
      googleAPIKey: 'AIzaSyAYMrXwuyaJte-nARkCegNT8nfR5RRhSks',
    },
    user: {
      shouldDisplayRegistrationPrizeCode: true,
    },
  },
  development: {
    analytics: {
      gtmId: 'GTM-KJ95JW5',
    },
    core: {
      googleAPIKey: 'AIzaSyAYMrXwuyaJte-nARkCegNT8nfR5RRhSks',
    },
    user: {
      shouldDisplayRegistrationPrizeCode: true,
    },
  },
  testing: {
    analytics: {
      gtmId: 'GTM-KJ95JW5',
    },
    core: {
      googleAPIKey: 'AIzaSyCpmjqsM8Msln_EqLRZsNIgQIy4pPpcuLw',
    },
    network: {
      apiUrl: 'https://apifront-betlytn-us-test01.gaming1.com',
      mediaUrl: 'https://media-betlytn-us-test01.gaming1.com',
      siteUrl: 'https://betlytn-us-test01.gaming1.com',
      wsUrl: [
        'wss://wss-betlytn-us-test01.gaming1.com:10002',
        'wss://wss02-betlytn-us-test01.gaming1.com:10022',
      ],
    },
    user: {
      shouldDisplayRegistrationPrizeCode: true,
    },
  },
  acceptance: {
    analytics: {
      gtmId: 'GTM-KJ95JW5',
    },
    core: {
      geoComply: {
        activated: true,
        installerKey: 'qCOcVaXSSy',
      },
      googleAPIKey: 'AIzaSyD5JiTG0g30qzw7_rOKLflxcL2Rzm7OUDM',
    },
    seo: {
      fullStoryOrgKey: 'o-1B7M0A-na1',
    },
    marketing: {
      appsFlyer: {
        devKey: 'sFgSNBsRJEJ32U8Co5CBoF',
      },
    },
    network: {
      apiUrl: 'https://apifront-betlytn-us-acc01.gaming1.com',
      mediaUrl: 'https://media-betlytn-us-acc01.gaming1.com',
      prismicUrl: 'https://betlytn.cdn.prismic.io/api/v2',
      siteUrl: 'https://betlytn-us-acc01.gaming1.com',
      wsUrl: [
        'wss://wss-betlytn-us-acc01.gaming1.com:10002',
        'wss://wss02-betlytn-us-acc01.gaming1.com:10022',
      ],
      zendeskUrl: 'https://support.tn.betly.com',
    },
    user: {
      shouldDisplayRegistrationPrizeCode: true,
    },
  },
  production: {
    analytics: {
      gtmId: 'GTM-PTKLSB4',
    },
    assetsManagement: {
      isImageOptimizationWithCdnEnabled: true,
    },
    core: {
      geoComply: {
        activated: true,
        environment: 'production',
        installerKey: 'qCOcVaXSSy',
        umsServerUrl: 'https://ums.geocomply.com/installer/url/',
      },
      googleAPIKey: 'AIzaSyCyUWvlPjjd3ATx10nobSVLh-5fVvsX5Ns',
      mobileAppIdentifiers: {
        android: 'com.gamewise.us.betly.tn',
        ios: '1618971968',
      },
      zendeskWidgetKey: '4b65889f-e827-428a-8d70-d2b437ae10d3',
    },
    marketing: {
      appsFlyer: {
        devKey: 'sFgSNBsRJEJ32U8Co5CBoF',
      },
    },
    network: {
      apiUrl: 'https://apifront.tn.betly.com',
      mediaUrl: 'https://media.tn.betly.com',
      prismicUrl: 'https://betlytn.cdn.prismic.io/api/v2',
      siteUrl: 'https://tn.betly.com',
      wsUrl: ['wss://wss01.tn.betly.com:443', 'wss://wss02.tn.betly.com:443'],
      zendeskUrl: 'https://support.tn.betly.com',
    },
    user: {
      inboxProjectKey: '6300d0dcc40dd9ec4912ba0e9b71768c',
      zendeskUserNameFieldId: 360021920337,
    },
    seo: {
      dataDog: {
        applicationId: '295b5ba2-3618-458a-83dc-909126d23a8a',
        clientToken: 'pubf13b87c12fb650604a64c75173544ba7',
      },
      fullStoryOrgKey: 'o-1B4RNZ-na1',
    },
  },
};

export const config: AppConfig = getConfig(baseConfig, configOverwrite);

export const config$ = new BehaviorSubject(config);
